import React from 'react';
import './card.css';

// Import images from the public folder
const icons = [
  "/assets/CardIcon1.png",
  "/assets/CardIcon2.png",
  "/assets/CardIcon3.png",
  "/assets/CardIcon4.png"
];

function Card() {
  return (
    <div 
      className="cards-section" 
      style={{ 
        marginTop: '-50px', 
        display: 'flex', 
        justifyContent: 'center',
        flexWrap: 'wrap', 
        gap: '40px',
        position: 'relative' 
      }}
    >
      {["Eco-Friendly Solution", "Insurance", "Build and Buy", "Safety & Verification"].map((title, index) => (
        <div key={index} className="card1">
          <div className="custom-card shadow-lg">
            {/* Icon with Circular Border */}
            <div className="icon-container">
              <img src={icons[index]} alt={title} className="icon-card" />
            </div>

            {/* Text & Line Section */}
            <div className="content-section">
              <div className="text-content">
                <h6 className="h6-content mb-0">{title}</h6>
              </div>
              <div className="line"></div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default Card;
