import React from 'react';

const cardData = [
  {
    id: 1,
    image: "/assets/maintaining1.png",
    text: "Find your place with an immersive photo experience and the most listings, including things you won’t find anywhere else."
  },
  {
    id: 2,
    image: "/assets/maintain2.png",
    text: "No matter what path you take to sell your home, we can help you navigate a successful sale."
  },
  {
    id: 3,
    image: "/assets/maintaining3.png",
    text: "We’re creating a seamless online experience – from shopping on the largest rental network, to applying, to paying rent."
  }
];

function Maintaining() {
  return (
    <div className='container' style={{ paddingTop: '100px', paddingBottom: '50px', backgroundColor:'white' }}>
      <h2 className='text-center mb-4'
        style={{ fontSize: '25px' }}
      >
        Whether you're maintaining, upgrading, or <br />
        managing your property, we’re here to help you
        <br />
        every step of the way
      </h2>

      <div className="container d-flex justify-content-center gap-4 flex-wrap">
        {cardData.map((card) => (
          <div 
            key={card.id} 
            className="shadow text-center" 
            style={{ 
              width: '300px', 
              height: '370px', 
              borderRadius: '10px', 
              paddingTop: '30px', 
              backgroundColor: '#ffffff',
              transition: '0.3s ease-in-out'
            }}
          >
            <img src={card.image} alt="card" style={{ height: '140px', width: '220px', margin: '0 auto' }} />
            <p 
              className="mt-3 p-3" 
              style={{ 
                fontFamily: 'Lato, sans-serif',
                fontWeight: 400,
                fontSize: '17px',
                lineHeight: '21.6px',
                letterSpacing: '0.3%',
                textAlign: 'center',
                height: '100px'
              }}
            >
              {card.text}
            </p>
            <button 
              className="learn-more-btn"
              style={{
                backgroundColor: '#048BC5',
                color: 'white',
                border: 'none',
                padding: '10px 20px',
                borderRadius: '8px',
                fontSize: '16px',
                cursor: 'pointer',
                transition: '0.3s ease-in-out',
                marginTop: '10px'
              }}
              onMouseOver={(e) => {
                e.target.style.backgroundColor = '#014751';
                e.target.style.transform = 'scale(1.05)';
              }}
              onMouseOut={(e) => {
                e.target.style.backgroundColor = '#048BC5';
                e.target.style.transform = 'scale(1)';
              }}
            >
              Learn More
            </button>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Maintaining;
