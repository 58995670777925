import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { FaTools, FaSmile, FaUsersCog, FaMapMarkerAlt } from "react-icons/fa";

const Counter = () => {
  const [counters, setCounters] = useState([0, 0, 0, 0]);
  const counterRef = useRef(null);
  const observer = useRef(null);

  useEffect(() => {
    observer.current = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          startCounting();
          observer.current.disconnect();
        }
      },
      { threshold: 0.5 }
    );

    if (counterRef.current) {
      observer.current.observe(counterRef.current);
    }

    return () => {
      if (observer.current) observer.current.disconnect();
    };
  }, []);

  const startCounting = () => {
    const targetNumbers = [5000, 3500, 1200, 800];

    targetNumbers.forEach((target, index) => {
      let count = 0;
      const increment = target / 100;
      const interval = setInterval(() => {
        count += increment;
        setCounters((prev) => {
          const newCounters = [...prev];
          newCounters[index] = Math.min(Math.floor(count), target);
          return newCounters;
        });

        if (count >= target) clearInterval(interval);
      }, 50);
    });
  };

  return (
    <section
      ref={counterRef}
      style={{
        padding: "50px 0",
       
        textAlign: "center",
      }}
    >
      <Container>
        {/* Updated Heading */}
        <h2 className="text-uppercase">Our Impact</h2>
        <p className="my-4" style={{ fontSize: "20px", fontWeight: "600", marginBottom:'100px' }}>
          Connecting You with Trusted Professionals – <br /> From Cleaners to Plumbers, Mechanics, and More!
        </p>

        <div 
        className="mt-5"
        >
        <Row>
          {/* Counter 1 - Jobs Completed */}
          <Col md={3} sm={6} style={{ marginBottom: "30px", textAlign: "center" }}>
            <FaTools style={{ fontSize: "50px", color: "#014751", marginBottom: "10px" }} />
            <span style={{ fontSize: "40px", fontWeight: "bold", color: "#000", display: "block" }}>
              {counters[0]}
            </span>
            <p style={{ fontSize: "14px", textTransform: "uppercase", letterSpacing: "1px", color: "#555" }}>
              Jobs Completed
            </p>
          </Col>

          {/* Counter 2 - Happy Clients */}
          <Col md={3} sm={6} style={{ marginBottom: "30px", textAlign: "center" }}>
            <FaSmile style={{ fontSize: "50px", color: "#014751", marginBottom: "10px" }} />
            <span style={{ fontSize: "40px", fontWeight: "bold", color: "#000", display: "block" }}>
              {counters[1]}
            </span>
            <p style={{ fontSize: "14px", textTransform: "uppercase", letterSpacing: "1px", color: "#555" }}>
              Happy Clients
            </p>
          </Col>

          {/* Counter 3 - Trusted Professionals */}
          <Col md={3} sm={6} style={{ marginBottom: "30px", textAlign: "center" }}>
            <FaUsersCog style={{ fontSize: "50px", color: "#014751", marginBottom: "10px" }} />
            <span style={{ fontSize: "40px", fontWeight: "bold", color: "#000", display: "block" }}>
              {counters[2]}
            </span>
            <p style={{ fontSize: "14px", textTransform: "uppercase", letterSpacing: "1px", color: "#555" }}>
              Trusted Professionals
            </p>
          </Col>

          {/* Counter 4 - Communities Served */}
          <Col md={3} sm={6} style={{ marginBottom: "30px", textAlign: "center" }}>
            <FaMapMarkerAlt style={{ fontSize: "50px", color: "#014751", marginBottom: "10px" }} />
            <span style={{ fontSize: "40px", fontWeight: "bold", color: "#000", display: "block" }}>
              {counters[3]}
            </span>
            <p style={{ fontSize: "14px", textTransform: "uppercase", letterSpacing: "1px", color: "#555" }}>
              Communities Served
            </p>
          </Col>
        </Row>
        </div>
      </Container>
    </section>
  );
};

export default Counter;
