import "./App.css";
import Card from "./components/cards";
import Header from "./components/Header";
import HeroSection from "./components/hero-section";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Button } from 'react-bootstrap';
import Maintaining from "./components/maintaining";
import WhoWeHelp from "./components/who-we-help/Index";
import Services from "./components/services";
import Reviews from "./components/reviews";
import Footer from "./components/footer";
import Counter from "./components/counter";
import AccordionTwoSection from "./components/accordian";
import Navbars from "./components/navbar";
function App() {
  return (
    <>
    <Navbars/>
      {/* <Header /> */}
      <HeroSection />
        <Card/>
        <Maintaining/>
        <WhoWeHelp/>
        <Services/>
        <Counter/>
        {/* <AccordionTwoSection/> */}
        <Reviews/>
        <Footer/>
    </>
  );
}

export default App;
