import React from "react";
import { Mail, Phone } from "lucide-react";

function Services() {
  return (
    <div className="py-5" style={{ backgroundColor: "#048BC5" }}>
      <div className="container">
        <div className="row align-items-center text-white">
          {/* Left Image */}
          <div className="col-lg-3 col-md-4 col-12 text-center mb-4 mb-md-0">
            <img
              src="/assets/sectionImg1.png"
              alt="Logo"
              className="img-fluid"
              style={{ maxHeight: "400px", maxWidth: "100%" }}
            />
          </div>

          {/* Main Text Content */}
          <div className="col-lg-5 col-md-6 col-12 text-center text-md-start">
            <h1 className="text-3xl fw-bold mb-3">
              We're Providing the Best Real Estate Services
            </h1>
            <p className="text-light opacity-90">
              Make a type specimen book. It has survived not only five
              centuries, but also the leap into.
            </p>

            {/* Contact Info */}
            <div className="row mt-4">
              {/* Phone */}
              <div className="col-md-6 col-12 d-flex align-items-center mb-3">
                <div
                  className="d-flex justify-content-center align-items-center me-3"
                  style={{
                    backgroundColor: "rgba(1, 71, 81, 0.32)",
                    width: "40px",
                    height: "40px",
                    borderRadius: "50%",
                  }}
                >
                  <Phone className="h-5 w-5 text-white" />
                </div>
                <div>
                  <div className="text-sm fw-semibold">Our Hot Line:</div>
                  <a
                    href="tel:(888)972-7121"
                    className="text-black fw-bold text-decoration-none"
                  >
                    (888) 972-7121
                  </a>
                </div>
              </div>

              {/* Mail */}
              <div className="col-md-6 col-12 d-flex align-items-center mb-3">
                <div
                  className="d-flex justify-content-center align-items-center me-3"
                  style={{
                    backgroundColor: "rgba(1, 71, 81, 0.32)",
                    width: "40px",
                    height: "40px",
                    borderRadius: "50%",
                  }}
                >
                  <Mail className="h-5 w-5 text-white" />
                </div>
                <div>
                  <div className="text-sm fw-semibold">Mail Us:</div>
                  <a
                    href="mailto:info@gmail.com"
                    className="text-black fw-bold text-decoration-none"
                  >
                    info@gmail.com
                  </a>
                </div>
              </div>
            </div>

            {/* Button */}
            <button
              className="btn mt-4 px-4 py-2 w-100 w-md-auto"
              style={{
                color: "white",
                backgroundColor: "#014751",
                fontWeight: "500",
                fontSize: "15px",
                border: "none",
                transition: "all 0.3s ease-in-out",
              }}
              onMouseOver={(e) => {
                e.target.style.backgroundColor = "#026b74";
                e.target.style.transform = "scale(1.05)";
              }}
              onMouseOut={(e) => {
                e.target.style.backgroundColor = "#014751";
                e.target.style.transform = "scale(1)";
              }}
            >
              REQUEST A FAIR CASH OFFER TODAY
            </button>
          </div>

          {/* Right Image */}
          <div className="col-lg-3 col-md-4 col-12 text-center mt-4 mt-md-0">
            <img
              src="/assets/sectionImg2.png"
              alt="servicesImg"
              className="img-fluid"
              style={{ maxHeight: "350px", maxWidth: "315px" }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Services;
