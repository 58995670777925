import React from "react";

const iconData = [
  { id: 1, image: "/assets/CardIcon1.png", label: "Loss of income" },
  { id: 2, image: "/assets/CardIcon2.png", label: "Divorce" },
  { id: 3, image: "/assets/CardIcon3.png", label: "Inherited Property" },
  { id: 4, image: "/assets/CardIcon4.png", label: "Relocation" },
  { id: 5, image: "/assets/CardIcon1.png", label: "Loss of income" },
  { id: 6, image: "/assets/CardIcon2.png", label: "Divorce" },
  { id: 7, image: "/assets/CardIcon3.png", label: "Inherited Property" },
  { id: 8, image: "/assets/CardIcon4.png", label: "Relocation" },
];

function WhoWeHelp() {
  return (
    <div className="bg-light py-4">
      <div className="container">
        <div className="my-5 text-center">
          <h2>Who We Help?</h2>
          <p>
            If you're facing any of the following situations, we'll buy your house
            within 3 to 30 days:
          </p>
        </div>

        {/* Icon Grid */}
        <div className="icon-grid">
          {iconData.map((icon) => (
            <div key={icon.id} className="icon-box">
              <div className="icon-circle">
                <img src={icon.image} alt={icon.label} />
              </div>
              <h4>{icon.label}</h4>
            </div>
          ))}
        </div>
      </div>

      {/* Internal CSS */}
      <style>
        {`
          .icon-grid {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            gap: 20px;
          }

          .icon-box {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            width: 120px;
            transition: transform 0.3s ease;
          }

          .icon-box:hover {
            transform: scale(1.1);
          }

          .icon-circle {
            width: 80px;
            height: 80px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: white;
            border-radius: 50%;
            box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
          }

          .icon-circle img {
            width: 40px;
            height: 40px;
          }

          h4 {
            font-family: "Lato", sans-serif;
            font-weight: 600;
            font-size: 12px;
            line-height: 24px;
            letter-spacing: 0.3%;
            margin-top: 10px;
          }

          /* Responsive Design */
          @media (max-width: 768px) {
            .icon-grid {
              gap: 10px;
            }

            .icon-box {
              width: 90px;
            }

            .icon-circle {
              width: 60px;
              height: 60px;
            }

            .icon-circle img {
              width: 30px;
              height: 30px;
            }

            h4 {
              font-size: 10px;
            }
          }
        `}
      </style>
    </div>
  );
}

export default WhoWeHelp;
