import React from "react";
import { FaFacebookF, FaTwitter, FaInstagram, FaLinkedin } from "react-icons/fa";
import { MdLocationOn, MdEmail, MdPhone, MdLanguage } from "react-icons/md";
import "./footer.css"; 

const Footer = () => {
  return (
    <footer className="text-center text-lg-start">
      {/* Social Media Section */}
      <section className="d-flex justify-content-center justify-content-lg-between p-4 border-bottom">
        <div className="me-5 d-none d-lg-block">
          <span>Connect with Property Doctor:</span>
        </div>
        <div className="social-icons">
          <a href="#"><FaFacebookF size={18} /></a>
          <a href="#"><FaTwitter size={18} /></a>
          <a href="#"><FaInstagram size={18} /></a>
          <a href="#"><FaLinkedin size={18} /></a>
        </div>
      </section>

      {/* Footer Links Section */}
      <section>
        <div className="container text-center text-md-start mt-5">
          <div className="row mt-3">
            {/* About Us */}
            <div className="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
              <h6>About Property Doctor</h6>
              <p>Your go-to expert for home maintenance, plumbing, electrical work, and repairs.</p>
            </div>

            {/* Services */}
            <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
              <h6>Services</h6>
              <p><a href="#">Plumbing</a></p>
              <p><a href="#">Electrical Repairs</a></p>
              <p><a href="#">Carpentry</a></p>
              <p><a href="#">Mechanic Services</a></p>
            </div>

            {/* Quick Links */}
            <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
              <h6>Quick Links</h6>
              <p><a href="#">About Us</a></p>
              <p><a href="#">Customer Reviews</a></p>
              <p><a href="#">FAQs</a></p>
              <p><a href="#">Contact</a></p>
            </div>

            {/* Contact Info */}
            <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4 contact-info">
              <h6>Contact</h6>
              <p><MdLocationOn /> London, UK</p>
              <p><MdEmail /> support@propertydoctor.com</p>
              <p><MdPhone /> +44 20 7946 0123</p>
              <p><MdLanguage /> www.propertydoctor.com</p>
            </div>
          </div>
        </div>
      </section>

      {/* Copyright */}
      <div className="text-center p-4 copyright">
        © 2024 Property Doctor. All Rights Reserved.
      </div>
    </footer>
  );
};

export default Footer;
