import React from "react";

const testimonials = [
  {
    img: "/assets/logo.png",
    name: "Luna John",
    location: "New York, USA",
    stars: 5,
    feedback:
      "We were so impressed with the work they did for us. They took our vision and turned it into reality, on time and within budget.",
    role: "UX Designer",
    time: "2 days ago",
  },
  {
    img: "/assets/img/testimonial-img-2.jpg",
    name: "Mark Smith",
    location: "Los Angeles, USA",
    stars: 4,
    feedback:
      "We needed a visually appealing and user-friendly website. We are so happy with the results and highly recommend them.",
    role: "Marketing Specialist",
    time: "1 week ago",
  },
  {
    img: "/assets/img/testimonial-img-4.jpg",
    name: "Luke Reeves",
    location: "London, UK",
    stars: 5,
    feedback:
      "We wanted a strong brand identity. They created a logo and marketing materials that truly represent our company.",
    role: "Sales Manager",
    time: "3 weeks ago",
  },
];

const cardStyle = {
  width: "100%",
  maxWidth: "500px",
  height: "auto",
  borderRadius: "15px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  padding: "20px",
  transition: "transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out",
  boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
  backgroundColor: "#fff",
  cursor: "pointer",
};

const hoverEffect = {
  transform: "scale(1.05)",
  boxShadow: "0 6px 15px rgba(0,0,0,0.2)",
};

const nameStyle = {
  fontSize: "clamp(16px, 1.5vw, 20px)", 
  fontFamily: "Poppins, sans-serif",
  fontWeight: "600",
};

const locationStyle = {
  fontSize: "clamp(12px, 1.2vw, 16px)", 
  fontFamily: "Poppins, sans-serif",
  fontWeight: "400",
  color: "#555",
};

const reviewStyle = {
  fontSize: "clamp(14px, 1.5vw, 18px)", 
  fontFamily: "Poppins, sans-serif",
  fontWeight: "400",
  color: "#333",
  lineHeight: "1.5",
};

const starStyle = {
  color: "#F1C644",
  fontSize: "clamp(16px, 2vw, 22px)", 
};

const imageStyle = {
  width: "70px",
  height: "70px",
  borderRadius: "50%",
  objectFit: "cover",
};

const timeStyle = {
  fontSize: "12px",
  color: "#777",
  textAlign: "right",
};

const Reviews = () => {
  return (
    <section className="testimonial-section bg-light py-5">
      <div className="container">
        <div className="text-center mb-5">
          <h2 className="text-uppercase">Testimonials</h2>
          <p className="my-4" style={{ fontSize: "clamp(16px, 1.8vw, 22px)", fontWeight: "500" }}>
            What Do Happy Clients Say About Working With Us?
          </p>
          <hr className="w-50 mx-auto border-dark-subtle" />
        </div>

        <div className="row gy-4 justify-content-center">
          {testimonials.map((testimonial, index) => (
            <div key={index} className="col-12 col-md-6 col-lg-4 d-flex justify-content-center">
              <div
                className="card border-0 shadow p-3"
                style={cardStyle}
                onMouseEnter={(e) => (e.currentTarget.style.transform = hoverEffect.transform)}
                onMouseLeave={(e) => (e.currentTarget.style.transform = "scale(1)")}
              >
                <div className="testimonial-header d-flex align-items-center">
                  <img src={testimonial.img} alt={testimonial.name} className="testimonial-img" style={imageStyle} />
                  <div className="ms-3">
                    <h4 className="mb-1" style={nameStyle}>{testimonial.name}</h4>
                    <p className="mb-1" style={locationStyle}>{testimonial.location}</p>
                    <div className="bsb-ratings mb-3" style={starStyle}>
                      {"★".repeat(testimonial.stars)}
                      {"☆".repeat(5 - testimonial.stars)}
                    </div>
                  </div>
                </div>

                <blockquote className="blockquote" style={reviewStyle}>
                  {testimonial.feedback}
                </blockquote>

                <p className="mt-3" style={timeStyle}>{testimonial.time}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Reviews;
