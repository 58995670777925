import React, { useState, useEffect } from 'react';
import { Navbar, Nav, Button, Container } from 'react-bootstrap';
import './navbar.css';

const Navbars = () => {
  const [isNavbarToggled, setIsNavbarToggled] = useState(false);
  const [activeLink, setActiveLink] = useState("/");

  return (
    <div className="">
      <header>
        <Navbar 
          expand="lg" 
          variant="light"
          className={`navbar ${isNavbarToggled ? "toggled" : ""}`}
        >
          <Container>
            {/* Logo */}
            <Navbar.Brand href="/">
              <img src="/assets/logo.png" alt="Logo" className="logo" />
            </Navbar.Brand>

            {/* Toggle Button for Mobile */}
            <Navbar.Toggle 
              aria-controls="navbar-nav" 
              onClick={() => setIsNavbarToggled(prev => !prev)} 
            />

            {/* Navigation Links */}
            <Navbar.Collapse id="navbar-nav" className={`${isNavbarToggled ? "show" : ""}`}>
              <Nav className="mx-auto">
                <Nav.Link 
                  className={`nav-item ${activeLink === "/" ? "active" : ""}`} 
                  href="/" 
                  onClick={() => setActiveLink("/")}
                >
                  Home
                </Nav.Link>
                <Nav.Link 
                  className={`nav-item ${activeLink === "/services" ? "active" : ""}`} 
                  href="/services" 
                  onClick={() => setActiveLink("/services")}
                >
                  Services
                </Nav.Link>
                <Nav.Link 
                  className={`nav-item ${activeLink === "/property" ? "active" : ""}`} 
                  href="/property" 
                  onClick={() => setActiveLink("/property")}
                >
                  Property
                </Nav.Link>
                <Nav.Link 
                  className={`nav-item ${activeLink === "/blogs" ? "active" : ""}`} 
                  href="/blogs" 
                  onClick={() => setActiveLink("/blogs")}
                >
                  Blogs
                </Nav.Link>
                <Nav.Link 
                  className={`nav-item ${activeLink === "/support" ? "active" : ""}`} 
                  href="/support" 
                  onClick={() => setActiveLink("/support")}
                >
                  Support
                </Nav.Link>
                <Nav.Link 
                  className={`nav-item ${activeLink === "/about" ? "active" : ""}`} 
                  href="/about" 
                  onClick={() => setActiveLink("/about")}
                >
                  About Us
                </Nav.Link>
                <Nav.Link 
                  className={`nav-item ${activeLink === "/contact" ? "active" : ""}`} 
                  href="/contact" 
                  onClick={() => setActiveLink("/contact")}
                >
                  Contact Us
                </Nav.Link>
              </Nav>

              {/* Buttons */}
              <div className="d-flex auth-buttons">
                <Button variant="outline-primary" className="login-button">Login</Button>
                <Button className='signup-button'>Sign Up</Button>
              </div>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </header>
    </div>
  );
};

export default Navbars;
