import React, { useState } from 'react';
import './HeroSection.css';
import { FaSearch, FaChevronDown } from 'react-icons/fa';



const HeroSection = () => {
  const [serviceMode, setServiceMode] = useState('general');

  return (
    <div className="section">
      <div className="background">
        <div className="black-background"></div>
        <div className="red-background">
          <img src="/assets/HeroSection.png" alt="" className="hero-img" />
        </div>
      </div>

      <div className="container">
        <div className="content">
          <h1 className="content-h1">Your Trusted Partner for Property Solutions</h1>
          <p>
            Property Doctor is your one-stop platform connecting property owners with trusted service professionals for all property-related needs. From maintenance and repairs to emergency services and eco-friendly solutions, we ensure safety, convenience, and quality.
          </p>
          <div className="buttons">
            <button className="general-btn">General</button>
            <button className="emergency-btn">Emergency</button>
          </div>
          <div className="search-container">
            <div className="dropdown-Service">
              <span className="Service-span">Service Name</span>
              <FaChevronDown className="icon-hero" />
            </div>
            <div className="search-input-container">
              <FaSearch className="icon-hero" />
              <input type="text" placeholder="Enter a problem or service" className="search-input" />
            </div>
            <button className="search-button">Search</button>
          </div>
          <div className="checkbox-container">
            <div className="checkbox">
              <input type="checkbox" className="our-recomandation" />
              <label htmlFor="verified-traders">Verified Traders</label>
            </div>
            <div className="checkbox">
              <input type="checkbox" className="our-recomandation" />
              <label htmlFor="our-recomandation">Our Recommendation</label>
            </div>
          </div>
        </div>

      
      </div>
     
    </div>
  );
};

export default HeroSection;
